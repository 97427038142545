import React from "react"
import AbsoluteImage from "../../common/AbsoluteImage"
import Image1 from "images/Product/AiShortVideo/1.png"
import Image2 from "images/Product/AiShortVideo/2.png"
import Image3 from "images/Product/AiShortVideo/3.png"
import Image4 from "images/Product/AiShortVideo/4.png"
import Image5 from "images/Product/AiShortVideo/5.png"
import Image6 from "images/Product/AiShortVideo/6.png"
import Image7 from "images/Product/AiShortVideo/7.png"
import StartBtn from "images/Product/AiShortVideo/8.png"

import Image1En from "images/Product/AiShortVideo/1-en.png"
import Image2En from "images/Product/AiShortVideo/2-en.png"
import Image3En from "images/Product/AiShortVideo/3-en.png"
import Image4En from "images/Product/AiShortVideo/4-en.png"
import Image5En from "images/Product/AiShortVideo/5-en.png"
import Image6En from "images/Product/AiShortVideo/6-en.png"
import Image7En from "images/Product/AiShortVideo/7-en.png"
import StartBtnEn from "images/Product/AiShortVideo/8-en.png"

import Image1EnMobile from "images/Product/AiShortVideo/mobile/1-en.png"
import Image2EnMobile from "images/Product/AiShortVideo/mobile/2-en.png"
import Image3EnMobile from "images/Product/AiShortVideo/mobile/3-en.png"
import Image4EnMobile from "images/Product/AiShortVideo/mobile/4-en.png"
import Image5EnMobile from "images/Product/AiShortVideo/mobile/5-en.png"
import StartBtnEnMobile from "images/Product/AiShortVideo/mobile/8-en.png"

import Image1ViMobile from "images/Product/AiShortVideo/mobile/1.png"
import Image2ViMobile from "images/Product/AiShortVideo/mobile/2.png"
import Image3ViMobile from "images/Product/AiShortVideo/mobile/3.png"
import Image4ViMobile from "images/Product/AiShortVideo/mobile/4.png"
import Image5ViMobile from "images/Product/AiShortVideo/mobile/5.png"
import StartBtnViMobile from "images/Product/AiShortVideo/mobile/8.png"

import { useFormatter } from "../../../helpers/i18n"
import { useAppContext } from "pages/common/layout"
import ContactForm from "pages/common/ContactForm"
import "./index.scss"

export default function AiShortVideo() {
  const { currentLocale } = useFormatter()
  const { isMobile } = useAppContext()

  const renderContentPage = () => {
    if (currentLocale === "vi") {
      if (isMobile) {
        return <AiShortVideoViMobile />
      }
      return <AiShortVideoVi />
    } else {
      if (isMobile) {
        return <AiShortVideoEnMobile />
      }
      return <AiShortVideoEn />
    }
  }

  return renderContentPage()
}

function AiShortVideoVi() {
  return (
    <div>
      <AbsoluteImage src={Image1} />
      <AbsoluteImage src={Image2} />
      <AbsoluteImage src={Image3} />
      <AbsoluteImage src={Image4} />
      <AbsoluteImage src={Image5} />
      <AbsoluteImage src={Image6} />
      <AbsoluteImage src={Image7}>
        <a href="#contact" className="start-btn">
          <img src={StartBtn} style={{ width: "100%" }} />
        </a>
      </AbsoluteImage>
      <ContactForm />
    </div>
  )
}

function AiShortVideoEn() {
  return (
    <div>
      <AbsoluteImage src={Image1En} />
      <AbsoluteImage src={Image2En} />
      <AbsoluteImage src={Image3En} />
      <AbsoluteImage src={Image4En} />
      <AbsoluteImage src={Image5En} />
      <AbsoluteImage src={Image6En} />
      <AbsoluteImage src={Image7En}>
        <a href="#contact" className="start-btn">
          <img src={StartBtnEn} style={{ width: "100%" }} />
        </a>
      </AbsoluteImage>
      <ContactForm />
    </div>
  )
}

function AiShortVideoViMobile() {
  return (
    <div>
      <AbsoluteImage src={Image1ViMobile} />
      <AbsoluteImage src={Image2ViMobile} />
      <AbsoluteImage src={Image3ViMobile} />
      <AbsoluteImage src={Image4ViMobile} />
      <AbsoluteImage src={Image5ViMobile}>
        <a href="#contact" className="start-btn-mobile">
          <img src={StartBtn} style={{ width: "100%" }} />
        </a>
      </AbsoluteImage>
      <ContactForm />
    </div>
  )
}

function AiShortVideoEnMobile() {
  return (
    <div>
      <AbsoluteImage src={Image1EnMobile} />
      <AbsoluteImage src={Image2EnMobile} />
      <AbsoluteImage src={Image3EnMobile} />
      <AbsoluteImage src={Image4EnMobile} />
      <AbsoluteImage src={Image5EnMobile}>
        <a href="#contact" className="start-btn-mobile">
          <img src={StartBtnEn} style={{ width: "100%" }} />
        </a>
      </AbsoluteImage>
      <ContactForm />
    </div>
  )
}
